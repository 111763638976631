export default {
    title: "Laser",
    icon: "mdi-laser-pointer",
    groups: ["Tecnici", "Laser", "Milkrun"],
    submenu: [
        {
            title: "Programmi",
            route: { name: "laser-programmi" },
            badge: { url: "/laser/programmi/conteggio_dafare/", tooltip: "Conteggio Programmi da fare" },
            groups: ["Tecnici"],
        },
        {
            title: "Da Nestare",
            route: { name: "laser-danestare" },
            badge: { url: "/laser/riepilogo/conteggio_da_nestare/", tooltip: "Conteggio ODP da nestare" },
            groups: ["Tecnici", "Laser"],
            floating_buttons: [
                {
                    title: "Aggiungi Job Manualmente",
                    icon: "mdi-clock-fast",
                    event: "fastjob",
                    admin: true,
                    groups: ["Schedulazione", "Tecnici"],
                },
            ],
        },
        {
            title: "In Taglio",
            route: { name: "laser-intaglio" },
            badge: { url: "/laser/avanzamento/conteggio/", tooltip: "Conteggio ODP in taglio" },
            groups: ["Tecnici", "Laser", "Milkrun"],
        },
        {
            title: "Sbancalatura",
            route: { name: "laser-sbancalatura" },
            badge: { url: "/laser/sbancalatura/conteggio/", tooltip: "Conteggio ODP in da sbancalare" },
            groups: ["Tecnici", "Laser", "Milkrun"],
        },
        {
            title: "Da Ubicare",
            route: { name: "laser-daubicare" },
            badge: { url: "/laser/sbancalatura/conteggio_da_ubicare/", tooltip: "Conteggio ODP da ubicare" },
            groups: ["Tecnici", "Laser", "Milkrun"],
        },
        {
            title: "Proposte",
            route: { name: "laser-proposte" },
            badge: { url: "/ordini/proposte/?conteggio=true", tooltip: "Conteggio Offerte" },
            groups: ["Amministrazione", "Schedulazione", "Tecnici", "Laser"],
        },
    ],
};
