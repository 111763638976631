const DaNestare = () => import("./DaNestare");
const Programmi = () => import("./Programmi");
const InTaglio = () => import("./InTaglio");
const Sbancalatura = () => import("./Sbancalatura");
const DaUbicare = () => import("./DaUbicare");
const Proposte = () => import("../ordini/Proposte");
import store from "../../store";

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next();
        return;
    }
    next("/login");
};

export default [
    {
        path: "/laser/intaglio/",
        name: "laser-intaglio",
        meta: { menu: "laser", title: "In taglio" },
        component: InTaglio,
        title: "Intaglio",
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/laser/danestare/",
        name: "laser-danestare",
        meta: { menu: "laser", title: "Da Nestare" },
        component: DaNestare,
        title: "Da Nestare",
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/laser/programmi/",
        name: "laser-programmi",
        meta: { menu: "laser", title: "Programmi" },
        component: Programmi,
        title: "Programmi",
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/laser/sbancalatura/",
        name: "laser-sbancalatura",
        meta: { menu: "laser", title: "Sbancalatura" },
        component: Sbancalatura,
        title: "Sbancalatura",
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/laser/daubicare/",
        name: "laser-daubicare",
        meta: { menu: "laser", title: "Da Ubicare" },
        component: DaUbicare,
        title: "Da Ubicare",
        beforeEnter: ifAuthenticated,
    },
    {
        path: "/laser/proposte/",
        name: "laser-proposte",
        meta: { menu: "laser", title: "Proposte" },
        component: Proposte,
        beforeEnter: ifAuthenticated,
    },
];
